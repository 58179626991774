import { useSiteURL } from "../../base/site"
import { jsonAuthor } from "../author"
import { jsonThumbnails } from "../image"

export function jsonPage({
    path,
    title,
    publishedAt,
    updatedAt,
}: {
    path: string
    title: string
    publishedAt: string
    updatedAt: string
}) {
    const url = useSiteURL(path)

    return {
        "@context": "http://schema.org",
        "@type": "WebPage",
        name: title,
        image: jsonThumbnails(),
        datePublished: publishedAt,
        dateModified: updatedAt,
        author: jsonAuthor(),
        url,
    }
}
