import React from "react"
import { graphql, PageProps } from "gatsby"
import { PageSinglePageQuery } from "../../../types/gatsby-graphql.generated"
import { jsonPage } from "../../json/content/page"
import { SinglePageHeadLayout, SinglePageLayout } from "../../layouts/single"
import { ContentHeader, InfoGroup, InfoWith } from "../../views/content"
import { Markdown } from "../../views/markdown"

export function Head({ data }: PageProps<PageSinglePageQuery>) {
    const { markdownRemark } = data

    if (!markdownRemark) {
        return null
    }

    const { title, description, path } = markdownRemark

    return (
        <SinglePageHeadLayout
            isHome={false}
            path={path}
            title={title}
            description={description}
            json={[jsonPage(markdownRemark)]}
        />
    )
}

export default function PageSinglePage({
    data,
}: PageProps<PageSinglePageQuery>) {
    const { markdownRemark } = data

    if (!markdownRemark) {
        return null
    }

    const { title, html } = markdownRemark

    return (
        <SinglePageLayout isHome={false}>
            <ContentHeader>
                <h1>{title}</h1>
                <InfoGroup>
                    <InfoWith {...markdownRemark} />
                </InfoGroup>
            </ContentHeader>
            <Markdown html={html} />
        </SinglePageLayout>
    )
}

export const pageQuery = graphql`
    query PageSinglePage($id: String!) {
        markdownRemark(
            frontmatter: { id: { eq: $id } }
            published: { eq: true }
        ) {
            path
            title
            description
            updatedAt
            publishedAt
            html
        }
    }
`
